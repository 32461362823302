import { Component, OnInit } from '@angular/core';

declare var $;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    if(typeof $ != "undefined") {
      $( ".sideoptions .inneroption a" ).click(function() {
        $( ".sideoptions .inneroption a" ).removeClass("active");
        $(this).addClass("active");
      });
    }
  }

}
