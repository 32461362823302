<header class="header" [ngClass]="{'fixed': windowScrolled}">
  <div class="container">
    <div class="header-row">
      <div class="logo">
        <a href="javascript:;" routerLink="/">
            <svg width="159" height="32" viewBox="0 0 159 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M149.593 31.8421H1.65012C0.747709 31.8421 0 31.0944 0 30.192V1.65012C0 0.74771 0.747709 0 1.65012 0H149.593C150.161 0 150.702 0.283614 150.986 0.773493L158.721 13.1752C159.03 13.6908 159.056 14.3354 158.772 14.8511C158.489 15.3667 157.921 15.7019 157.328 15.7019H151.244V30.192C151.244 31.1202 150.496 31.8421 149.593 31.8421ZM3.30024 28.5419H147.918V14.0518C147.918 13.1494 148.665 12.4017 149.568 12.4017H154.312L148.639 3.30024H3.27445V28.5419H3.30024Z" fill="white"/>
                <path d="M9.72032 20.188C9.7461 20.5231 9.7461 20.8325 9.82345 21.1162C9.97815 21.7865 10.5454 22.2248 11.3189 22.3022C12.4791 22.4311 13.5362 22.1475 14.516 21.5803C14.9543 21.3224 14.9801 21.3482 15.2895 21.735C15.6246 22.1733 15.9598 22.6373 16.295 23.0757C16.5528 23.4108 16.5271 23.5655 16.1919 23.8234C14.6191 24.9578 12.8401 25.3961 10.9063 25.2156C9.79767 25.1125 8.76635 24.8031 7.88972 24.1328C6.8584 23.3335 6.29117 22.2764 6.2396 20.9872C6.18804 19.6723 6.18804 18.3574 6.2396 17.0424C6.29117 15.1861 7.50297 13.6391 9.28201 13.0718C10.7001 12.6077 12.1439 12.582 13.562 13.0203C15.341 13.5617 16.5271 15.0571 16.6302 16.9135C16.6818 17.8675 16.656 18.7957 16.6818 19.7497C16.6818 20.0848 16.5528 20.188 16.1403 20.188C14.1292 20.188 12.0924 20.188 10.0813 20.188H9.72032ZM13.2784 17.8933C13.2784 17.1198 13.33 16.3463 12.5565 15.8822C11.8345 15.4697 11.0868 15.4697 10.3649 15.908C9.64297 16.3721 9.72032 17.1456 9.72032 17.8675H13.2784V17.8933Z" fill="white"/>
                <path d="M23.0244 21.8389H23.3338C24.7261 21.8389 26.1183 21.8389 27.5106 21.8389C28.0005 21.8389 28.1036 21.942 28.1036 22.4319C28.1036 23.1023 28.1036 23.7727 28.1036 24.443C28.1036 24.8556 28.0005 24.9587 27.588 24.9587C24.5971 24.9587 21.6321 24.9587 18.6412 24.9587C18.2545 24.9587 18.1514 24.8556 18.1514 24.4688C18.1514 24.0563 18.1514 23.6695 18.1514 23.257C18.1514 22.9476 18.2287 22.6898 18.4092 22.4577C19.9304 20.4208 21.4516 18.4098 22.9728 16.3729C23.0244 16.2955 23.0759 16.2182 23.1533 16.1151H22.8439C21.5547 16.1151 20.2656 16.1151 18.9764 16.1151C18.5639 16.1151 18.4608 16.0119 18.4608 15.5994C18.4608 14.9033 18.4608 14.1813 18.4608 13.4852C18.4608 13.15 18.5639 13.0469 18.8733 13.0469C21.7352 13.0469 24.5971 13.0469 27.4591 13.0469C27.7942 13.0469 27.8974 13.15 27.8974 13.4852C27.8974 13.8719 27.8974 14.2587 27.8974 14.6454C27.8974 14.9548 27.82 15.2127 27.6395 15.4447C26.1441 17.4816 24.6487 19.5184 23.1533 21.5553C23.1791 21.6326 23.1275 21.71 23.0244 21.8389Z" fill="white"/>
                <path d="M94.9854 13.3558C95.8105 13.0464 96.6355 12.969 97.4606 12.9948C98.8013 13.0464 100.013 13.4589 100.89 14.516C101.586 15.3669 101.947 16.3724 101.998 17.4553C102.05 18.5124 102.05 19.5953 102.05 20.6524C102.05 21.9931 102.05 23.3596 102.05 24.7003C102.05 25.1128 101.947 25.216 101.534 25.216C100.709 25.216 99.91 25.216 99.0849 25.216C98.6208 25.216 98.5177 25.1386 98.5177 24.6488C98.5177 22.5861 98.5177 20.5235 98.5177 18.4351C98.5177 18.1257 98.4919 17.8163 98.4661 17.5069C98.3114 16.5787 97.7958 16.1146 96.8418 16.063C96.3004 16.0372 95.7589 16.1146 95.2175 16.2693C95.037 16.3208 94.9854 16.3982 94.9854 16.6045C94.9854 19.2601 94.9854 21.89 94.9854 24.5456C94.9854 25.1902 94.9338 25.2418 94.2893 25.2418C93.5158 25.2418 92.7165 25.2418 91.943 25.2418C91.582 25.2418 91.4531 25.1128 91.4531 24.7777C91.4531 19.6211 91.4531 14.4902 91.4531 9.33363C91.4531 8.97266 91.5563 8.84375 91.943 8.84375C92.7939 8.84375 93.6447 8.84375 94.4955 8.84375C94.8565 8.84375 94.9596 8.94688 94.9596 9.30785C94.9596 10.5454 94.9596 11.783 94.9596 13.0464L94.9854 13.3558Z" fill="white"/>
                <path d="M61.4675 17.8417C61.4933 17.3518 61.4417 16.9393 61.2613 16.5268C61.055 16.0627 60.6682 15.8564 60.1784 15.8048C58.9666 15.6501 57.8321 15.8564 56.7234 16.3463C56.3367 16.5267 56.2336 16.501 56.0273 16.14C55.7437 15.6244 55.4858 15.0829 55.2022 14.5673C54.996 14.1547 54.996 14.0516 55.4085 13.8453C56.5172 13.2265 57.7032 12.8656 58.9666 12.7624C59.8432 12.6851 60.694 12.6851 61.5449 12.8914C63.4786 13.3555 64.6388 14.6188 64.8709 16.6041C64.9225 17.094 64.974 17.5839 64.974 18.048C64.974 20.0333 64.974 21.9928 64.974 23.9781C64.974 24.4422 64.9225 24.5195 64.4841 24.6484C62.4731 25.1899 60.462 25.4219 58.3736 25.1641C57.7805 25.0867 57.1618 24.9062 56.5945 24.6742C55.2022 24.107 54.5319 22.9983 54.4803 21.5029C54.4287 19.6981 55.3569 18.4863 57.2649 17.9964C58.4509 17.687 59.6627 17.687 60.8745 17.8159C61.0808 17.8159 61.2613 17.8417 61.4675 17.8417ZM61.4675 22.2248V20.2137C61.0808 20.0848 59.9721 20.0075 59.4564 20.0848C59.1986 20.1106 58.9408 20.1622 58.683 20.2653C58.3736 20.3685 58.1931 20.6005 58.0899 20.9099C57.9352 21.5545 58.2446 22.0701 58.9408 22.2764C59.7916 22.5084 60.6167 22.3795 61.4675 22.2248Z" fill="white"/>
                <path d="M125.177 20.1876C125.177 20.4196 125.151 20.6259 125.177 20.8322C125.28 21.683 125.848 22.1987 126.724 22.3018C127.884 22.4307 128.941 22.1471 129.921 21.5798C130.36 21.322 130.385 21.3478 130.695 21.7603C131.03 22.1987 131.365 22.6628 131.7 23.1011C131.958 23.4362 131.932 23.5909 131.597 23.8488C130.024 24.9832 128.245 25.4215 126.312 25.2411C125.203 25.1379 124.172 24.8285 123.295 24.1582C122.264 23.3589 121.696 22.3018 121.645 21.0126C121.593 19.6977 121.593 18.3827 121.645 17.0678C121.696 15.2114 122.908 13.6645 124.687 13.0972C126.105 12.6331 127.549 12.6074 128.967 13.0457C130.721 13.5613 131.881 15.031 132.01 16.8616C132.087 17.8413 132.061 18.8211 132.061 19.8008C132.061 20.1102 131.932 20.2134 131.597 20.2134C130.875 20.2134 130.153 20.2134 129.406 20.2134C128.091 20.2134 126.75 20.2134 125.435 20.2134L125.177 20.1876ZM128.709 17.8929C128.709 17.1194 128.761 16.3717 128.013 15.9076C127.291 15.4692 126.544 15.4692 125.822 15.9076C125.074 16.3717 125.151 17.1452 125.151 17.8929C125.177 17.8929 128.709 17.8929 128.709 17.8929Z" fill="white"/>
                <path d="M67.396 19.6211C67.396 17.9194 67.396 16.2178 67.396 14.5419C67.396 14.1293 67.4733 14.0262 67.8601 13.8715C69.6649 13.2011 71.4955 12.866 73.4292 13.0464C74.8473 13.1754 76.0849 13.6652 76.9873 14.8255C77.5803 15.599 77.8897 16.5014 77.9413 17.4553C77.9928 18.3835 77.9928 19.3375 77.9928 20.2657C77.9928 21.7611 77.9928 23.2307 77.9928 24.7261C77.9928 25.1902 77.8897 25.2676 77.4514 25.2676C76.6263 25.2676 75.8271 25.2676 75.002 25.2676C74.5637 25.2676 74.4606 25.1902 74.4606 24.7261C74.4606 22.6377 74.4606 20.5751 74.4606 18.4867C74.4606 18.1515 74.4348 17.7905 74.3832 17.4553C74.2543 16.7076 73.7644 16.2435 73.0167 16.1404C72.3721 16.0631 71.7533 16.1146 71.1345 16.2435C70.9541 16.2693 70.9283 16.3982 70.9283 16.5529C70.9283 19.2344 70.9283 21.9416 70.9283 24.623C70.9283 24.7004 70.9283 24.8035 70.9283 24.9066C70.9025 25.1129 70.7994 25.2418 70.5931 25.2418C69.6649 25.2418 68.7367 25.2418 67.7827 25.2418C67.5249 25.2418 67.4218 25.1129 67.4218 24.8035C67.4218 24.0042 67.4218 23.2049 67.4218 22.4057V19.6211H67.396Z" fill="white"/>
                <path d="M115.096 12.6602C116.617 12.6602 118.035 13.0211 119.35 13.7173C119.659 13.872 119.685 14.0267 119.505 14.3103C119.169 14.8775 118.834 15.4447 118.499 16.012C118.319 16.3214 118.164 16.3729 117.854 16.1924C116.926 15.7283 115.972 15.5221 114.941 15.5736C114.709 15.5994 114.477 15.651 114.271 15.7283C113.935 15.8573 113.781 16.0893 113.781 16.4245C113.781 16.7597 113.961 17.0175 114.271 17.0948C114.838 17.2495 115.405 17.3785 115.972 17.5074C116.823 17.6879 117.648 17.8941 118.396 18.3324C119.401 18.9254 120.02 19.7763 120.072 20.9623C120.149 22.7929 119.453 24.1337 117.648 24.804C117.416 24.8814 117.184 24.9587 116.952 25.0103C114.554 25.5002 112.285 25.165 110.223 23.8243C109.862 23.5922 109.836 23.4633 110.042 23.0765C110.377 22.5351 110.687 21.9678 111.022 21.4264C111.228 21.0912 111.357 21.0654 111.692 21.2717C112.749 21.9678 113.91 22.303 115.173 22.2257C115.482 22.1999 115.766 22.1225 116.05 22.0194C116.359 21.8905 116.514 21.6327 116.514 21.2975C116.488 20.9623 116.307 20.756 115.998 20.6787C115.457 20.524 114.889 20.3951 114.322 20.2662C113.446 20.0599 112.595 19.8021 111.847 19.3122C109.784 17.9973 110.042 15.2385 111.202 14.0782C111.847 13.4336 112.62 13.0727 113.471 12.918C114.039 12.7891 114.554 12.7375 115.096 12.6602Z" fill="white"/>
                <path d="M42.6202 13.6142C43.3163 13.6142 43.9609 13.6142 44.6313 13.6142C45.0438 13.6142 45.1469 13.6916 45.1469 14.1299C45.1469 14.8002 45.1469 15.4448 45.1469 16.1152C45.1469 16.5277 45.0438 16.6051 44.657 16.6051C43.9867 16.6051 43.3163 16.6051 42.5944 16.6051V16.9145C42.5944 19.4928 42.5944 22.0969 42.5944 24.6752C42.5944 25.165 42.517 25.2424 42.0272 25.2424C41.2021 25.2424 40.4028 25.2424 39.5778 25.2424C39.191 25.2424 39.0879 25.1393 39.0879 24.7525C39.0879 20.8077 39.0879 16.8629 39.0879 12.9181C39.0879 11.8094 39.3715 10.7523 40.1192 9.90146C40.8154 9.0764 41.7693 8.66387 42.8007 8.58652C43.5226 8.53495 44.2445 8.58652 44.9406 8.6123C45.25 8.6123 45.3532 8.76697 45.3532 9.07637C45.3532 9.7983 45.3532 10.5202 45.3532 11.2164C45.3532 11.5258 45.2758 11.5774 44.9664 11.5774C44.6313 11.5516 44.2961 11.5258 43.9867 11.5516C43.1616 11.6031 42.7491 11.9641 42.6202 12.7892C42.5944 13.0728 42.6202 13.3306 42.6202 13.6142Z" fill="white"/>
                <path d="M80.2109 18.873C80.2367 18.1769 80.2109 17.3518 80.3656 16.5525C80.7008 14.5672 81.9384 13.3812 83.8721 12.9171C85.7801 12.453 87.5849 12.7109 89.2866 13.6906C89.6991 13.9227 89.7249 14.1031 89.4671 14.4899C89.1061 15.0313 88.7451 15.5728 88.3842 16.1142C88.1779 16.4494 88.0232 16.4752 87.688 16.2947C87.0692 15.9595 86.3989 15.779 85.7027 15.8048C84.5167 15.8306 83.8464 16.501 83.8206 17.6612C83.7948 18.5378 83.7948 19.3887 83.8206 20.2653C83.8464 21.4771 84.4909 22.1217 85.7285 22.1475C86.502 22.1732 87.2239 21.967 87.8685 21.5802C88.3068 21.3224 88.41 21.3482 88.6936 21.7607C89.0545 22.3022 89.4155 22.8436 89.7765 23.385C90.0085 23.7202 89.9827 23.8491 89.6475 24.0812C88.2295 25.0351 86.6825 25.3703 84.9808 25.2414C84.1815 25.1898 83.3823 25.0094 82.6603 24.6484C81.2938 23.9265 80.4945 22.7662 80.3141 21.2708C80.2367 20.4973 80.2625 19.7239 80.2109 18.873Z" fill="white"/>
                <path d="M46.7966 19.6469C46.7966 17.9452 46.7966 16.2693 46.7966 14.5676C46.7966 14.2067 46.9255 14.0004 47.2607 13.8715C49.0398 13.0722 50.9219 12.8401 52.8557 13.1238C53.0877 13.1495 53.3198 13.2011 53.5518 13.2269C53.8612 13.2785 53.9643 13.4074 53.9643 13.7168C53.9643 14.3871 53.9643 15.0575 53.9643 15.7279C53.9643 16.0888 53.8354 16.1662 53.4744 16.1662C52.7783 16.1404 52.0564 16.1146 51.3602 16.1404C51.1024 16.1404 50.8446 16.2435 50.5867 16.2951C50.4063 16.3209 50.3547 16.424 50.3547 16.6045C50.3547 18.4608 50.3547 20.3172 50.3547 22.1994C50.3547 23.076 50.3547 23.9269 50.3547 24.8035C50.3547 25.1903 50.2516 25.2934 49.8648 25.2934C49.014 25.2934 48.1889 25.2934 47.3381 25.2934C46.9255 25.2934 46.8224 25.1903 46.8224 24.7777C46.7966 23.0245 46.7966 21.3228 46.7966 19.6469Z" fill="white"/>
                <path d="M108.005 19.2864C108.005 21.0912 108.005 22.896 108.005 24.7008C108.005 25.1649 107.927 25.2423 107.463 25.2423C106.638 25.2423 105.813 25.2423 104.988 25.2423C104.601 25.2423 104.498 25.1391 104.498 24.7524C104.498 21.0912 104.498 17.43 104.498 13.7688C104.498 13.4078 104.601 13.3047 104.988 13.3047C105.839 13.3047 106.715 13.3047 107.566 13.3047C107.927 13.3047 108.03 13.4078 108.03 13.7946C108.005 15.6252 108.005 17.4558 108.005 19.2864Z" fill="white"/>
                <path d="M106.253 10.8027C104.886 10.8027 103.855 9.77136 103.855 8.43064C103.855 7.06413 104.86 6.08438 106.253 6.05859C107.645 6.05859 108.625 7.03835 108.625 8.43064C108.65 9.82292 107.645 10.8027 106.253 10.8027Z" fill="white"/>
                <path d="M34.7564 19.5174H30.8632C30.7085 19.5174 30.5796 19.3885 30.5796 19.2338V16.7328C30.5796 16.5781 30.7085 16.4492 30.8632 16.4492H34.7564C34.9111 16.4492 35.0401 16.5781 35.0401 16.7328V19.2338C35.0401 19.4143 34.9111 19.5174 34.7564 19.5174Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M137.451 25.345H142.865C143.406 25.345 143.845 24.9325 143.819 24.3911V13.6395C143.819 13.1239 143.406 12.6855 142.865 12.6855H137.451C136.935 12.6855 136.497 13.0981 136.497 13.6395V24.3911C136.497 24.9067 136.909 25.345 137.451 25.345Z" fill="white"/>
            </svg>
        </a>
      </div>
      <div class="header-menu">
        <nav class="nav">
          <ul>
            <li>
              <a herf="javascript:;" routerLink="" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }">Home</a>
            </li>
            <!-- <li>
              <a herf="javascript:;" routerLink="/how-it-works" (click)="" routerLinkActive="active-link">How it works</a>
            </li> -->
            <li>
              <a herf="javascript:;" routerLink="/franchises" routerLinkActive="active-link">Available Franchises</a>
            </li>
            <!-- <li>
              <a herf="javascript:;" (click)=";setFranchiseNaivgate();" routerLink="/add-your-franchise" routerLinkActive="active-link">Add your Franchise</a>
            </li> -->
            <li>
              <a herf="javascript:;" routerLink="/franchise-guide" routerLinkActive="active-link">Franchising Guide</a>
            </li>
          </ul>
          <ul class="nav-item-left">
            <li>
              <!-- <a href="javascript:;" routerLink="/apply-for-franchise" (click)="setFranchiseNaivgate();" class="header-nav-link">Apply for Franchise</a> -->
            </li>
            <!-- <li>
                <a href="javascript:;" routerLink="/add-your-franchise" (click)="setFranchiseNaivgate();" class= "">Add your Franchise</a>
            </li> -->
          </ul>
        </nav>
          <a href="javascript:;" routerLink="/apply-for-franchise" (click)="setFranchiseNaivgate();" class="btn-primary btn-sm">Apply for Franchise</a>
        <!-- Login Dropdown -->
        <div class="login-header" #toggleButton (click)="preventCloseOnClick()" [ngClass]="{'show': initDropdown}" >
            <div class="login-btn" (click)="loginDropdown()">

                <!-- <svg class="btn-lines" width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M0.944444 12H16.0556C16.575 12 17 11.46 17 10.8C17 10.14 16.575 9.6 16.0556 9.6H0.944444C0.425 9.6 0 10.14 0 10.8C0 11.46 0.425 12 0.944444 12ZM0.944444 7.2H16.0556C16.575 7.2 17 6.66 17 6C17 5.34 16.575 4.8 16.0556 4.8H0.944444C0.425 4.8 0 5.34 0 6C0 6.66 0.425 7.2 0.944444 7.2ZM0 1.2C0 1.86 0.425 2.4 0.944444 2.4H16.0556C16.575 2.4 17 1.86 17 1.2C17 0.54 16.575 0 16.0556 0H0.944444C0.425 0 0 0.54 0 1.2Z"
                      fill="#063088" />
                </svg> -->
                <div class="avatar">
                  <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="12" cy="12" r="12" fill="#053A9E"/>
<path d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12 3.6C13.992 3.6 15.6 5.208 15.6 7.2C15.6 9.192 13.992 10.8 12 10.8C10.008 10.8 8.4 9.192 8.4 7.2C8.4 5.208 10.008 3.6 12 3.6ZM12 20.64C9 20.64 6.348 19.104 4.8 16.776C4.836 14.388 9.6 13.08 12 13.08C14.388 13.08 19.164 14.388 19.2 16.776C17.652 19.104 15 20.64 12 20.64Z" fill="white"/>
</svg> -->
                    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 17.5V15.75C15 14.8217 14.6313 13.9315 13.9749 13.2751C13.3185 12.6187 12.4283 12.25 11.5 12.25H4.5C3.57174 12.25 2.6815 12.6187 2.02513 13.2751C1.36875 13.9315 1 14.8217 1 15.75V17.5" fill="white"/>
                    <path d="M15 17.5V15.75C15 14.8217 14.6312 13.9315 13.9749 13.2751C13.3185 12.6187 12.4283 12.25 11.5 12.25H4.5C3.57174 12.25 2.6815 12.6187 2.02513 13.2751C1.36875 13.9315 1 14.8217 1 15.75V17.5H15Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.00342 8.75C9.93642 8.75 11.5034 7.183 11.5034 5.25C11.5034 3.317 9.93642 1.75 8.00342 1.75C6.07042 1.75 4.50342 3.317 4.50342 5.25C4.50342 7.183 6.07042 8.75 8.00342 8.75Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                </div>
          </div>
          <ul *ngIf="!loggedUser" class="login-dropdown" #menu>
            <li>
                <a href="javascript:;" routerLink="/sign-in">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.2422 0H1.75781C0.788555 0 0 0.788555 0 1.75781V4.10156H1.17188V1.75781C1.17188 1.43473 1.43473 1.17188 1.75781 1.17188H13.2422C13.5653 1.17188 13.8281 1.43473 13.8281 1.75781V13.2422C13.8281 13.5653 13.5653 13.8281 13.2422 13.8281H1.75781C1.43473 13.8281 1.17188 13.5653 1.17188 13.2422V10.8984H0V13.2422C0 14.2114 0.788555 15 1.75781 15H13.2422C14.2114 15 15 14.2114 15 13.2422V1.75781C15 0.788555 14.2114 0 13.2422 0Z" fill="#9F9F9F"/>
                  <path d="M7.03125 3.8584L6.20262 4.68703L8.42918 6.91359H0V8.08547H8.42918L6.20262 10.312L7.03125 11.1407L10.6724 7.49953L7.03125 3.8584Z" fill="#9F9F9F"/>
                  </svg>Login
                </a>
            </li>
            <li>
                <a href="javascript:;" routerLink="/sign-up">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.44922 15C1.12561 15 0.863281 14.7377 0.863281 14.4141C0.863281 11.474 3.25522 9.08203 6.19531 9.08203H7.07422C7.67589 9.08203 8.26669 9.18164 8.83019 9.37808C9.13575 9.4846 9.29712 9.8187 9.19057 10.1242C9.08404 10.4298 8.74994 10.5912 8.4444 10.4846C8.00522 10.3315 7.54423 10.2539 7.07422 10.2539H6.19531C3.9014 10.2539 2.03516 12.1201 2.03516 14.4141C2.03516 14.7377 1.77283 15 1.44922 15ZM10.5312 3.95508C10.5312 1.77425 8.757 0 6.57617 0C4.39534 0 2.62109 1.77425 2.62109 3.95508C2.62109 6.13591 4.39534 7.91016 6.57617 7.91016C8.757 7.91016 10.5312 6.13591 10.5312 3.95508ZM9.35938 3.95508C9.35938 5.48974 8.11083 6.73828 6.57617 6.73828C5.04151 6.73828 3.79297 5.48974 3.79297 3.95508C3.79297 2.42042 5.04151 1.17188 6.57617 1.17188C8.11083 1.17188 9.35938 2.42042 9.35938 3.95508ZM13.5488 11.4844H11.791V9.72656C11.791 9.40295 11.5287 9.14062 11.2051 9.14062C10.8815 9.14062 10.6191 9.40295 10.6191 9.72656V11.4844H8.86133C8.53771 11.4844 8.27539 11.7467 8.27539 12.0703C8.27539 12.3939 8.53771 12.6562 8.86133 12.6562H10.6191V14.4141C10.6191 14.7377 10.8815 15 11.2051 15C11.5287 15 11.791 14.7377 11.791 14.4141V12.6562H13.5488C13.8724 12.6562 14.1348 12.3939 14.1348 12.0703C14.1348 11.7467 13.8724 11.4844 13.5488 11.4844Z" fill="#9F9F9F"/>
                  </svg>Sign up
                </a>
            </li>
          </ul>

          <ul *ngIf="loggedUser" class="login-dropdown" #menu>
            <li>
                <a href="javascript:;" routerLink="/dashboard/franchisee">
                  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_306_8165)">
                    <path d="M6.93745 6.50005H1.31245C0.58873 6.50005 0 5.91132 0 5.18745V1.81245C0 1.08873 0.58873 0.5 1.31245 0.5H6.93745C7.66132 0.5 8.25005 1.08873 8.25005 1.81245V5.18745C8.25005 5.91132 7.66132 6.50005 6.93745 6.50005ZM1.31245 1.625C1.20905 1.625 1.125 1.70905 1.125 1.81245V5.18745C1.125 5.291 1.20905 5.37505 1.31245 5.37505H6.93745C7.041 5.37505 7.12505 5.291 7.12505 5.18745V1.81245C7.12505 1.70905 7.041 1.625 6.93745 1.625H1.31245Z" fill="#9F9F9F"/>
                    <path d="M6.93745 18.5H1.31245C0.58873 18.5 0 17.9113 0 17.1876V9.31259C0 8.58873 0.58873 8 1.31245 8H6.93745C7.66132 8 8.25005 8.58873 8.25005 9.31259V17.1876C8.25005 17.9113 7.66132 18.5 6.93745 18.5ZM1.31245 9.125C1.20905 9.125 1.125 9.20905 1.125 9.31259V17.1876C1.125 17.291 1.20905 17.375 1.31245 17.375H6.93745C7.041 17.375 7.12505 17.291 7.12505 17.1876V9.31259C7.12505 9.20905 7.041 9.125 6.93745 9.125H1.31245Z" fill="#9F9F9F"/>
                    <path d="M16.6876 18.5H11.0626C10.3387 18.5 9.75 17.9113 9.75 17.1876V13.8126C9.75 13.0887 10.3387 12.5 11.0626 12.5H16.6876C17.4113 12.5 18 13.0887 18 13.8126V17.1876C18 17.9113 17.4113 18.5 16.6876 18.5ZM11.0626 13.625C10.959 13.625 10.875 13.709 10.875 13.8126V17.1876C10.875 17.291 10.959 17.375 11.0626 17.375H16.6876C16.791 17.375 16.875 17.291 16.875 17.1876V13.8126C16.875 13.709 16.791 13.625 16.6876 13.625H11.0626Z" fill="#9F9F9F"/>
                    <path d="M16.6876 11H11.0626C10.3387 11 9.75 10.4113 9.75 9.68745V1.81245C9.75 1.08873 10.3387 0.5 11.0626 0.5H16.6876C17.4113 0.5 18 1.08873 18 1.81245V9.68745C18 10.4113 17.4113 11 16.6876 11ZM11.0626 1.625C10.959 1.625 10.875 1.70905 10.875 1.81245V9.68745C10.875 9.791 10.959 9.87505 11.0626 9.87505H16.6876C16.791 9.87505 16.875 9.791 16.875 9.68745V1.81245C16.875 1.70905 16.791 1.625 16.6876 1.625H11.0626Z" fill="#9F9F9F"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_306_8165">
                    <rect width="18" height="18" fill="white" transform="translate(0 0.5)"/>
                    </clipPath>
                    </defs>
                  </svg>Dashboard
                </a>
            </li>
            <li>
                <a href="javascript:;" (click)="logout()">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.2422 0H1.75781C0.788555 0 0 0.788555 0 1.75781V4.10156H1.17188V1.75781C1.17188 1.43473 1.43473 1.17188 1.75781 1.17188H13.2422C13.5653 1.17188 13.8281 1.43473 13.8281 1.75781V13.2422C13.8281 13.5653 13.5653 13.8281 13.2422 13.8281H1.75781C1.43473 13.8281 1.17188 13.5653 1.17188 13.2422V10.8984H0V13.2422C0 14.2114 0.788555 15 1.75781 15H13.2422C14.2114 15 15 14.2114 15 13.2422V1.75781C15 0.788555 14.2114 0 13.2422 0Z" fill="#9F9F9F"/>
                    <path d="M3.64111 11.1406L4.46975 10.312L2.24318 8.08543H10.6724V6.91356L2.24318 6.91356L4.46975 4.68699L3.64111 3.85836L-1.90735e-05 7.49949L3.64111 11.1406Z" fill="#9F9F9F"/>
                  </svg>Logout
                </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Desktop Header End -->

    <!-- Mobile Menu Button -->
    <button type="button" id="menuBtn" class="menu-btn" (click)="togglSidebar()">
      <span></span>
      <span></span>
      <span></span>
    </button>

    <!-- Sidebar Overlay -->
    <div class="sidebar-overlay" (click)="togglSidebar()" [ngClass]="{'show': initSidebar}">
    </div>
  </div>
</header>


<!-- Mobile Sidebar -->
<div id="sideBar" class="sidebar" [ngClass]="{'show': initSidebar}">
  <div class="sidebar-logo">
    <a href="javascript:;" routerLink="/" (click)="togglSidebar()">
      <img src="assets/images/new/logo-mobile.svg" alt="" />
    </a>
  </div>
  <div *ngIf="!loggedUser" class="user-accordion" >
    <div class="userAaccordion-head" (click)="toogleAccordion()">
      <div class="user-avatar">
        <img src="images" src="assets/images/new/avatar.svg" alt="" />
      </div>
      <a href="javascript:;"(click)="togglSidebar();" routerLink="/sign-in">
          <span>Login/SignUp</span>
      </a>
    </div>
  </div>

  <div *ngIf="loggedUser" class="user-accordion" [ngClass]="{'show': initAccordion}">
    <div class="userAaccordion-head" (click)="toogleAccordion()">
      <div class="user-avatar">
        <img src="images" src="assets/images/new/avatar.svg" alt="" />
      </div>
      <span>{{loggedUser.full_name}}</span>
      <i class="fa fa-plus" aria-hidden="true" [ngClass]="{'d-none': initAccordion}"></i>
      <i class="fa fa-minus d-none" aria-hidden="true" [ngClass]="{'d-block': initAccordion}"></i>
    </div>
    <div class="userAaccordion-body">
      <ul class="sidebar-list">
        <li>
          <a href="javascript:;" routerLink="/dashboard/profile" (click)="togglSidebar()">
            <img src="assets/images/new/user-icon.svg" alt="" />
            Profile
          </a>
        </li>
        <li>
          <a href="javascript:;"  routerLink="/dashboard/franchisee" (click)="togglSidebar()">
            <img src="assets/images/new/dashboard-icon.svg" alt="" />
            Dashboard
          </a>
        </li>

        <li>
          <a href="javascript:;" (click)="logout(); togglSidebar()">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M-1.04907e-06 12L-1.74846e-07 2C-7.82707e-08 0.895311 0.895312 -1.14565e-06 2 -1.04907e-06L12 -1.74846e-07C13.1047 -7.82707e-08 14 0.895313 14 2L14 12C14 13.1047 13.1047 14 12 14L2 14C0.895311 14 -1.14565e-06 13.1047 -1.04907e-06 12ZM4.64656 5.14656L3.14656 6.64656C2.95125 6.84187 2.95125 7.15844 3.14656 7.35344L4.64656 8.85344C4.83844 9.05219 5.155 9.0575 5.35344 8.86562C5.55219 8.67375 5.5575 8.35719 5.36562 8.15875C5.36156 8.15469 5.3575 8.15062 5.35344 8.14656L4.70687 7.5L8 7.5C8.27625 7.5 8.5 7.27625 8.5 7C8.5 6.72375 8.27625 6.5 8 6.5L4.70687 6.5L5.35344 5.85344C5.54531 5.65469 5.53969 5.33812 5.34125 5.14656C5.1475 4.95937 4.84031 4.95937 4.64656 5.14656ZM10.5 11C10.7762 11 11 10.7762 11 10.5L11 3.5C11 3.22375 10.7762 3 10.5 3L9 3C8.72375 3 8.5 3.22375 8.5 3.5C8.5 3.77625 8.72375 4 9 4L10 4L10 10L9 10C8.72375 10 8.5 10.2237 8.5 10.5C8.5 10.7762 8.72375 11 9 11L10.5 11Z" fill="#052D84"/>
                </svg>
            Logout
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- User Info End -->
  <div class="sidebar-menu">
    <ul class="sidebar-list">
      <li>
        <a href="javascript:;" (click)="togglSidebar();" routerLink="/">
          <img src="assets/images/new/home-icon.svg" alt="" />
          Home
        </a>
      </li>
      <!-- <li>
        <a href="javascript:;" (click)="togglSidebar();" routerLink="/how-it-works">
          <img src="assets/images/new/settings-icon.svg" alt="" />
          How it works
        </a>
      </li> -->
      <li>
        <a href="javascript:;" routerLink="/franchises" (click)="togglSidebar();">
          <img src="assets/images/new/file-icon.svg" alt="" />
          Available Franchises
        </a>
      </li>
      <!-- <li>
        <a href="javascript:;" (click)="togglSidebar();;setFranchiseNaivgate();" routerLink="/add-your-franchise">
          <img src="assets/images/new/add-file.svg" alt="" />
          Add your Franchise
        </a>
      </li> -->
      <li>
        <a href="javascript:;" (click)="togglSidebar();" routerLink="/franchise-guide">
          <img src="assets/images/new/card-icon.svg" alt="" />
          Franchise guide
        </a>
      </li>
      <li>
        <a href="javascript:;" (click)="togglSidebar();" routerLink="/about-us">
          <img src="assets/images/new/about-us-icon.svg" alt="" />
          About Us
        </a>
      </li>
      <li>
        <a href="javascript:;" (click)="togglSidebar();" routerLink="/contact-us">
          <img src="assets/images/new/phone-icon.svg" alt="" />
          Contact Us
        </a>
      </li>
      <li>
        <a href="javascript:;" (click)="togglSidebar();" routerLink="/disclaimer">
          <img src="assets/images/new/info-icon.svg" alt="" />
          Disclaimer
        </a>
      </li>
      <li>
        <a href="javascript:;" routerLink="/privacy-policy" (click)="togglSidebar();" routerLink="/privacy-policy">
          <img src="assets/images/new/lock-icon.svg" alt="" />
          Privacy Policy
        </a>
      </li>
    </ul>
  </div>

  <!-- Add Franchise button -->
  <a href="javascript:;" class="btn-primary" (click)="togglSidebar();;setFranchiseNaivgate();" routerLink="/apply-for-franchise">Apply for Franchise</a>
</div>
