export class AppSettings {
  // public static API_ENDPOINT       = 'http://192.168.18.30:8000/api/';
  // public static TEMP_IMAGES        = 'http://192.168.18.30:8000/uploads/temp/';


  public static API_ENDPOINT                      = 'https://api.ez-franchise.com/api/';
  public static TEMP_IMAGES                       = 'https://tempfiles.ez-franchise.com/';
  public static IMG_ENDPOINT                      = 'https://api.ez-franchise.com/public/';
  public static ENDPOINT_GRAPH_OVERALL            = 'dashboard/franchisor/graph';
  public static ENDPOINT_GRAPH_APPLICATIONS       = 'dashboard/franchisor/graph/applications';
  public static GRAPH_LEADS                       = 'dashboard/franchisor/graph/leads';
  public static GRAPH_SEARCH_VIEWS                = 'dashboard/franchisor/graph/search';
  public static abc           = 'dashboard/franchisor/graph/leads';
  public static FILE_DOWNLOAD                     = 'https://api.ez-franchise.com/public/docs/';
  // public static GOOGLE_MAPS_KEY    = 'AIzaSyDkG81UvqVdgrc5IhMuq4YkMOW2utyD9rQ';
  // public static GOOGLE_CAPTCHA_KEY = '6LdQq-AUAAAAAIHkxYNuM9mU-uVa1l56iKNn1N2T';
  public static GOOGLE_CAPTCHA_KEY = '6Le4N98UAAAAAJGL_xTX4V_MGi0MydzecdlRRBjE';
  // public static GOOGLE_LOGIN_KEY   = '661530475988-qqk0vro507gdu7vnsdfpkm047a821h59.apps.googleusercontent.com'; // Gharbaar Login key
  public static GOOGLE_LOGIN_KEY   = '661530475988-pur6mpkf1ihsdehinfdlpe9q4l2t5mdv.apps.googleusercontent.com'; // EzFranchise login key
  public static GOOGLE_MAPS_KEY    = 'AIzaSyDkwqsIbqWQR3ZH3n9WEfJ8YDdgCFDLZsg';
  // public static FACBOOK_APP_KEY    = '626742448065864';
  public static FACBOOK_APP_KEY    = '1604854313235049';

  public static notFoundUrl     = "404";

  public static searchPrices    = [ 500000, 1000000, 2000000, 3500000, 5000000, 6500000, 8000000, 10000000, 12500000, 15000000,
    17500000, 20000000, 25000000, 30000000, 40000000, 50000000, 75000000, 100000000,
    250000000, 500000000, 1000000000, 5000000000
  ];

  public static cities  = [
    {"id": 11, "name" : "Islamabad"},
    {"id": 5,  "name" : "Rawalpindi"},
    {"id": 2,  "name" : "Lahore"},
    {"id": 1,  "name" : "Karachi"},
  ];

  public static categories  = ["all", "retail", "food"];

  public static birthYear  = [ 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004,
      2003, 2002, 2001, 2000, 1999, 1998, 1997, 1996, 1995, 1994, 1993, 1992, 1991, 1990, 1989, 1988, 1987, 1986, 1985, 1984, 1983, 1982,
      1981, 1980, 1979, 1978, 1977, 1976, 1976, 1975, 1974, 1973, 1972, 1971, 1970, 1969, 1968, 1967, 1966, 1965, 1964, 1963, 1962, 1961,
      1960, 1959, 1958, 1957, 1956, 1955, 1954, 1953, 1952, 1951, 1950, 1949, 1948, 1947, 1946, 1945, 1944, 1943, 1942, 1941, 1940, 1939,
      1938,  1937, 1936, 1935, 1934, 1933, 1932, 1931, 1930, 1929, 1928, 1927, 1926, 1925, 1924, 1923, 1922, 1921, 1920
  ];
  public static months  = [
    { name:"January",  value: "01"},
    { name:"February", value: "02"},
    { name:"March",    value: "03"},
    { name:"April", value: "04"},
    { name:"May", value: "05"},
    { name:"June", value: "06"},
    { name:"July", value: "07"},
    { name:"August", value: "08"},
    { name:"September", value: "09"},
    { name:"October", value: "10"},
    { name:"November", value: "11"},
    { name:"December" , value: "12"},
  ];
  public static days  = ["01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31"];
}
