import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule, PreloadAllModules, NoPreloading } from '@angular/router';
import { AuthGuard } from './services/_helpers';

const routes: Routes = [
  // Site Pages
  { path: '',                         loadChildren: () => import('./Site/new-design/home/home.module').then(m => m.HomeModule) },
  { path: 'index.php',                loadChildren: () => import('./Site/new-design/home/home.module').then(m => m.HomeModule) },
  { path: 'home',                     redirectTo: '' },
  // { path: '',                         loadChildren: () => import('./Site/pages/home/home.module').then(m => m.HomeModule) },
  // { path: 'index.php',                loadChildren: () => import('./Site/pages/home/home.module').then(m => m.HomeModule) },
  // { path: 'home',                     redirectTo: '' },

  // { path: 'franchises',               loadChildren: () => import('./Site/pages/franchises-list/franchises-list.module').then(m => m.FranchisesListModule) },
  // { path: 'franchise/:slug',          loadChildren: () => import('./Site/pages/franchises-detail/franchises-detail.module').then(m => m.FranchisesDetailModule) },
  { path: 'franchises',               loadChildren: () => import('./Site/new-design/franchise-list/franchise-list.module').then(m => m.FranchiseListModule) },
  { path: 'franchise/chaaye-khana-18',          loadChildren: () => import('./Site/new-design/franchises/chaaye-khana/chaaye-khana.module').then(m => m.ChaayeKhanaModule)},
  // { path: 'franchise/:slug',          loadChildren: () => import('./Site/new-design/franchise-description/franchise-description.module').then(m => m.FranchiseDescriptionModule) },

  // Franchise Routes
  //{ path: 'franchise/miniso-20',          loadChildren: () => import('./Site/new-design/franchises/miniso/miniso.module').then(m => m.MinisoModule) },
  { path: 'franchise/jessies-burgers-5',
    loadChildren: () => import('./Site/new-design/franchises/jessies-burger/jessies.module').then(m => m.JessiesModule)},
  { path: 'franchise/wild-wings-15',
    loadChildren: () => import('./Site/new-design/franchises/wild-wings/wild-wings.module').then(m => m.WildWingsModule)},
  { path: 'franchise/tayto-12',
    loadChildren: () => import('./Site/new-design/franchises/tayto/tayto.module').then(m => m.TaytoModule) },
  { path: 'franchise/khas-stores-16',
    loadChildren: () => import('./Site/new-design/franchises/khas/khas.module').then(m => m.KhasModule) },
  { path: 'franchise/coral-beauty-emporium-25',
    loadChildren: () => import('./Site/new-design/franchises/coral-beauty-emporium/coral-beauty-emporium.module').then(m => m.CoralBeautyEmporiumModule) },
  { path: 'franchise/chinatown-14',
    loadChildren: () => import('./Site/new-design/franchises/china-town/china-town.module').then(m => m.ChinaTownModule) },
  { path: 'franchise/pizza-originale-10',
    loadChildren: () => import('./Site/new-design/franchises/pizza-originale/pizza-originale.module').then(m => m.PizzaOriginaleModule)},
  { path: 'franchise/mr-cod-300',
    loadChildren: () => import('./Site/new-design/franchises/mr-cod/mr-cod.module').then(m => m.MrCodModule)},
  { path: 'franchise/miniso-20',
    loadChildren: () => import('./Site/new-design/franchises/miniso-second/miniso-second.module').then(m => m.MinisoSecondModule) },
  { path: 'franchise/Mr-Cod-franchise-for-sale-300',
    loadChildren: () => import('./Site/new-design/franchises/mr-cod/mr-cod.module').then(m => m.MrCodModule) },
  { path: 'franchise/jade-cafe-13',
    loadChildren: () => import('./Site/new-design/franchises/jade-cafe/jade-cafe.module').then(m => m.JadeCafeModule)},
    //

  { path: 'about-us-old',                  loadChildren: () => import('./Site/pages/reviews/reviews.module').then(m => m.ReviewsModule) },
  { path: 'contact',                  loadChildren: () => import('./Site/pages/contact/contact.module').then(m => m.ContactModule) },
  { path: 'schedule-call',            loadChildren: () => import('./Site/pages/schedule-call/schedule-call.module').then(m => m.ScheduleCallModule) },

  // { path: 'login',                    loadChildren: () => import('./Site/pages/login/login.module').then(m => m.LoginModule) },
  // { path: 'signup',                   loadChildren: () => import('./Site/pages/signup/signup.module').then(m => m.SignupModule) },
  { path: 'privacy',                  loadChildren: () => import('./Site/pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  { path: 'disclaimer-old',               loadChildren: () => import('./Site/pages/disclaimer/disclaimer.module').then(m => m.DisclaimerModule) },

  // // Apply For Franchise Routes
  // {
  //   path: 'apply-for-franchise',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('./Site/pages/applyfranchise/applyfranchise.module').then(m => m.ApplyfranchiseModule)
  // },

  {path: 'thankyou', redirectTo: 'thankyou/', pathMatch: 'full'},
  { path: 'thankyou/:status',                 loadChildren: () => import('./Site/pages/thankyoupage/thankyoupage.module').then(m => m.ThankyoupageModule) },

  { path: 'ultimate-guide-to-franchising',  loadChildren: () => import('./Site/pages/guide/guide.module').then(m => m.GuideModule) },

  { path: 'franchise-guide/what-is-franchising', loadChildren: () => import('./Site/pages/what-is-franchising/what-is-franchising.module').then(m => m.WhatIsFranchisingModule) },
  { path: 'franchise-guide/benefits-of-franchising', loadChildren: () => import('./Site/pages/benefits-of-franchise/benefits-of-franchise.module').then(m => m.BenefitsOfFranchiseModule) },
  { path: 'franchise-guide/franchise-buying-advice', loadChildren: () => import('./Site/pages/franchise-buying-advice/franchise-buying-advice.module').then(m => m.FranchiseBuyingAdviceModule) },
  { path: 'franchise-guide/steps-to-buy-franchise', loadChildren: () => import('./Site/pages/steps-buy-franchise/steps-buy-franchise.module').then(m => m.StepsBuyFranchiseModule) },
  { path: 'franchise-guide/difference-between-franchise-and-business-opportunity', loadChildren: () => import('./Site/pages/franchise-and-business/franchise-and-business.module').then(m => m.FranchiseAndBusinessModule) },
  { path: 'franchise-guide/cost-of-starting-franchise', loadChildren: () => import('./Site/pages/cost-franchise/cost-franchise.module').then(m => m.CostFranchiseModule) },
  { path: 'franchise-guide/franchise-disclosure-document', loadChildren: () => import('./Site/pages/franchise-disclosure-document/franchise-disclosure-document.module').then(m => m.FranchiseDisclosureDocumentModule) },
  { path: 'franchise-guide/business-plan-for-franchise', loadChildren: () => import('./Site/pages/business-plan/business-plan.module').then(m => m.BusinessPlanModule) },
  { path: 'franchise-guide/signing-franchise-agreement', loadChildren: () => import('./Site/pages/signing-franchise/signing-franchise.module').then(m => m.SigningFranchiseModule) },
  { path: 'franchise-guide/infographic-history-of-franchising', loadChildren: () => import('./Site/pages/infographics/infographics.module').then(m => m.InfographicsModule) },

  // { path: 'apply-franchise',                loadChildren: () => import('./Site/new-design/apply-franchise/apply-franchise.module').then(m => m.ApplyFranchiseModule) },

  // Apply For Franchise Routes
  {
    path: 'apply-for-franchise',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./Site/new-design/apply-franchise/apply-franchise.module').then(m => m.ApplyFranchiseModule)
  },

  {
    path: 'apply-for-franchise/:slug',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./Site/new-design/apply-franchise/apply-franchise.module').then(m => m.ApplyFranchiseModule)
  },

  {
    path: 'edit-application/:applicationId',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./Site/new-design/apply-franchise/apply-franchise.module').then(m => m.ApplyFranchiseModule)
  },

  { path: 'about-us',                       loadChildren: () => import('./Site/new-design/about-us/about-us.module').then(m => m.AboutUsModule) },
  { path: 'disclaimer',                     loadChildren: () => import('./Site/new-design/disclaimer/disclaimer.module').then(m => m.DisclaimerModule) },
  { path: 'how-it-works',                   loadChildren: () => import('./Site/new-design/how-it-works/how-it-works.module').then(m => m.HowItWorksModule) },
  { path: 'privacy-policy',                 loadChildren: () => import('./Site/new-design/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },

  { path: 'franchise-guide',                loadChildren: () => import('./Site/new-design/franchise-guide/franchise-guide.module').then(m => m.FranchiseGuideModule) },
  { path: 'add-your-franchise', redirectTo: 'add-your-franchise/', pathMatch: 'full'},
  { path: 'add-your-franchise/:editableId',             loadChildren: () => import('./Site/new-design/add-your-franchise/add-your-franchise.module').then(m => m.AddYourFranchiseModule) },
  { path: 'contact-us',                     loadChildren: () => import('./Site/new-design/contact-us/contact-us.module').then(m => m.ContactUsModule) },

  // Oboard Screens
  { path: 'sign-in',                        loadChildren: () => import('./Site/new-design/sign-in/sign-in.module').then(m => m.SignInModule) },
  { path: 'forgot-password',                loadChildren: () => import('./Site/new-design/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
  { path: 'update-password/:token',         loadChildren: () => import('./Site/new-design/update-password/update-password.module').then(m => m.UpdatePasswordModule) },
  { path: 'sign-up',                        loadChildren: () => import('./Site/new-design/sign-up/sign-up.module').then(m => m.SignUpModule) },

  { path: 'login',                          redirectTo: 'sign-in'  },
  { path: 'signup',                         redirectTo: 'sign-up'  },


  // { path: 'dashboard-new', loadChildren: () => import('./Site/new-design/dashboard/dashboard.module').then(m => m.DashboardModule) },

  // Dashboard Page
  { path: 'dashboard',                       canActivate: [AuthGuard], loadChildren: () => import('./Site/dashboard/dashboard.module').then(m => m.DashboardModule) },

  // 404 Routes
  { path: '404',                              loadChildren: () => import('./Site/pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) },
  { path: '**',                               loadChildren: () => import('./Site/pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) },


];

@NgModule({
  // imports: [
  //    RouterModule.forRoot(routes, {
  //     initialNavigation: 'enabled'
  //   })
  // ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
        useHash: false,
        // preloadingStrategy: PreloadAllModules,
        preloadingStrategy: NoPreloading,
        initialNavigation: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
