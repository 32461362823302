import { Injectable, Inject  } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { DOCUMENT } from '@angular/common';

// import { WINDOW } from '@ng-toolkit/universal'
declare var $;

@Injectable({ providedIn: 'root' })
export class SEOService  {

    constructor(
      private title: Title,
      private meta: Meta,
      private _location  : Location,
      // @Inject(WINDOW) private window: Window,
      @Inject(DOCUMENT) private doc
    ) {
        // By Default set no index
        this.setNoIndex(false);
    }

    updateTitle(title: string) {
      this.title.setTitle(title);
    }

    updateDescription(desc: string) {
      this.meta.updateTag({ name: 'description', content: desc })
    }

    updateOgTitle(title: string) {
      this.meta.updateTag({ property: 'og:title', content: title })
    }

    updateOgDesc(desc: string) {
      this.meta.updateTag({ property: 'og:description', content: desc })
    }

    updateOgUrl(currentHref = "https://ez-franchise.com" + this._location['_platformLocation'].pathname) {

      // let  currentHref = this._location['_platformLocation'].protocol + '//' + this._location['_platformLocation'].hostname + this._location['_platformLocation'].pathname;
      // let  currentHref = "https://ez-franchise.com" + this._location['_platformLocation'].pathname;
      this.meta.updateTag({ property: 'og:url', content: currentHref })
    }

    updateOgImage(url: string) {
      this.meta.updateTag({ property: 'og:image', content: url })
    }

    updateTwitterTitle(title: string) {
      this.meta.updateTag({ property: 'twitter:title', content: title })
    }

    updateTwitterDesc(desc: string) {
      this.meta.updateTag({ property: 'twitter:description', content: desc })
    }

    updateCanonicalUrl(currentHref = "https://ez-franchise.com" + this._location['_platformLocation'].pathname) {

      // let  currentHref = this._location['_platformLocation'].protocol + '//' + this._location['_platformLocation'].hostname + this._location['_platformLocation'].pathname;
      // let currentHref =  "https://gharbaar.com" + this._location['_platformLocation'].pathname;
      // console.log("updateCanonicalUrl url: ",url)
      if(typeof $ != "undefined")
        $('link[rel="canonical"]').attr('href', currentHref);
      // // let link: HTMLLinkElement = this.doc.createElement('link');
      // // link.setAttribute('rel', 'canonical');
      // // this.doc.head.appendChild(link);
      // // link.setAttribute('href', url);

      // // Uncomment this for Universal
      // console.log("this.doc: ",this.doc)
      // console.log("this.doc: ",this.doc.getElementsByTagName("link"))
      // console.log("this.doc: ",this.doc.getElementById("canonicalLink"))
      // // let link: HTMLLinkElement = this.doc.createElement('link');
      let link: HTMLLinkElement = this.doc.getElementById('canonicalLink');
      // link.setAttribute('rel', 'canonical');
      // this.doc.head.appendChild(link);
      link.setAttribute('href', currentHref);
    }

    setNoIndex(bool) {
      if(bool)
        this.meta.addTag({ name: 'robots', content: 'noindex' });
      else
        this.meta.removeTag('name="robots"');
    }

    addListPageBreadCrumb(){
      let json = `{
      "@context": "https://schema.org/",
      "@type": "BreadcrumbList",
      "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://ez-franchise.com/"
      },{
      "@type": "ListItem",
      "position": 2,
      "name": "Franchise List",
      "item": "https://ez-franchise.com/franchises"
      }]
      }
      `;

      let d  = this.doc;
      let s  = 'script';
      let id = 'breadcrumb-json';

      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s);
      js.id = id;
      js.type = "application/ld+json";
      js.innerHTML = json;
      // var b = document.getElementsByName("body");
      // d.body.appendChild(js);
      // (b as any).appendChild(js);
      fjs.parentNode.insertBefore(js, fjs);
    }

    addBreadCrumbSchema(fname) {
      var currentHref = "https://ez-franchise.com" + this._location['_platformLocation'].pathname;
      let json = `{
        "@context": "https://schema.org/",
        "@type": "BreadcrumbList",
        "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://ez-franchise.com/"
        },{
        "@type": "ListItem",
        "position": 2,
        "name": "Franchise List",
        "item": "https://ez-franchise.com/franchises"
        },{
        "@type": "ListItem",
        "position": 3,
        "name": "`+fname+`",
        "item": "`+currentHref +`"
        }]
      }`;

      let d  = this.doc;
      let s  = 'script';
      let id = 'breadcrumb-json';

      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s);
      js.id = id;
      js.type = "application/ld+json";
      js.innerHTML = json;
      // var b = document.getElementsByName("body");
      // d.body.appendChild(js);
      // (b as any).appendChild(js);
      fjs.parentNode.insertBefore(js, fjs);
    }

    addBreadCrumbUltGuiding(){
      let json = `{
        "@context": "https://schema.org/",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://ez-franchise.com/"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "Ultimate Guide to Franchising",
          "item": "https://ez-franchise.com/ultimate-guide-to-franchising"
        }]
      }`;

      let d  = this.doc;
      let s  = 'script';
      let id = 'breadcrumb-json';

      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s);
      js.id = id;
      js.type = "application/ld+json";
      js.innerHTML = json;
      // var b = document.getElementsByName("body");
      // d.body.appendChild(js);
      // (b as any).appendChild(js);
      fjs.parentNode.insertBefore(js, fjs);
    }

    addBreadCrumbGuiding(breadName){
      var currentHref = "https://ez-franchise.com" + this._location['_platformLocation'].pathname;
      let json = `{
        "@context": "https://schema.org/",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://ez-franchise.com/"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "Ultimate Guide to Franchising",
          "item": "https://ez-franchise.com/ultimate-guide-to-franchising"
        },{
          "@type": "ListItem",
          "position": 3,
          "name": "`+breadName+`",
          "item": "`+currentHref+`"
        }]
      }`;

      let d  = this.doc;
      let s  = 'script';
      let id = 'breadcrumb-json';

      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s);
      js.id = id;
      js.type = "application/ld+json";
      js.innerHTML = json;
      // var b = document.getElementsByName("body");
      // d.body.appendChild(js);
      // (b as any).appendChild(js);
      fjs.parentNode.insertBefore(js, fjs);
    }

}
