<div class="sidebar">
    <div class="logo-brand">LOGO</div>
    <div class="sideoptions">
        <div class="dropdown">
            <button class="namebtn dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <div class="usideimg"><img src="assets/images/icons/user-avatar.svg" alt="" /></div>Hi,
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
              <button class="dropdown-item" type="button" routerLink="dashboard/account">Account</button>
              <button class="dropdown-item" type="button">Logout</button>
            </div>
        </div>
        <div class="inneroption getstarted">
            <a href="" routerLink="dashboard/getting-started" routerLinkActive="active"> Getting Started</a>
        </div>
        <div class="sidetext">Tools</div>
        <div class="inneroption compaign">
            <a href="" routerLink="dashboard/compaign" routerLinkActive="active"> Compaigns</a>
        </div>
        <div class="inneroption goals">
            <a href="" routerLink="dashboard/creategoal" routerLinkActive="active"> Goals</a>
        </div>
        <div class="sidetext">Settings</div>
        <div class="inneroption pixel">
            <a href="" routerLink="dashboard/pixelinstall" routerLinkActive="active"> Pixel Install</a>
        </div>
        <div class="inneroption help">
            <a href="" routerLink=""> Help Center</a>
        </div>
    </div>
</div>