import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import { AuthenticationService } from './authentication.service';
import { AppSettings } from './app.setting';
import { BehaviorSubject, Observable, throwError, Subject } from 'rxjs';
import { catchError, retry, finalize, tap, map, takeUntil } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class HelperService {
    public corsHeaders: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    });

    public searchParms       : any;
    public searchTypes_Seq   : any;
    public agencySearchParms : any;
    public investSearchParms : any;

    // Global For hiding email bucket
    public hideEmailBucket : any = true;

    // Https Params
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    public httpLoading     = false;

    constructor(
      private http                  : HttpClient,
      private authenticationService : AuthenticationService,
      private _location             : Location,
    ) {
        // Set Params OnLoad
        if(typeof localStorage != "undefined") {
          this.searchParms       = JSON.parse(localStorage.getItem('searchParms'));
          this.agencySearchParms = JSON.parse(localStorage.getItem('agencySearchParms'));
          this.investSearchParms = JSON.parse(localStorage.getItem('investSearchParms'));
        }

        let subscription = this.authenticationService.currentUserS$.subscribe(user => {
          if (user) {
              this.corsHeaders = {
                    headers: new HttpHeaders()
                      .set('Content-Type',  'application/json')
                      .set('Accept',  'application/json')
                      .set('Authorization',  `Bearer ${user.access_token}`)
                  }
          } else {
              this.corsHeaders = new HttpHeaders({
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json',
                                      });
          }
        });
    }



    public getCurrentHostName() {
      return this._location['_platformLocation'].hostname;
    }

    public getIPAddress(): Promise<any> {
      // return this.http.get("");

      // Set loader true
      this.httpLoading = true;

      return this.http.get('https://api.ipify.org/?format=json')
          .pipe( takeUntil(this.ngUnsubscribe) )
          .toPromise()
          .then( resp => {
              // Set loader false
              this.httpLoading = false;

              return resp;
              // console.log("resp: ",resp);
          })
          .catch(error => {
              // Set loader false
              this.httpLoading = false;
              console.log("helperFunc error: ",error);

              // Show Error Msg
              if(typeof error.error != "undefined") {
                  if(error.error.message == "Unauthenticated.") {
                    this.authenticationService.logout();
                  }

                  throw error;
              } else {
                  throw "Something went wrong. Please try again.";
              }
          });
    }

    public redirectTo404() {
      window.location.replace(AppSettings.notFoundUrl);
    }

    public setSearchTypes_Seq(seq) {
      this.searchTypes_Seq = seq;
    }

    public getSearchTypes_Seq() {
      return this.searchTypes_Seq;
    }

    public setHideEmailBucket(bool: any) {
      this.hideEmailBucket = bool;
    }

    public getHideEmailBucket() {
      return this.hideEmailBucket;
    }

    public setSearchParms(params) {
      if(typeof localStorage != "undefined") {
        localStorage.setItem('searchParms', JSON.stringify(params));
        this.searchParms = params;
      }
    }

    public getSearchParms() {
      return this.searchParms;
    }

    public setAgencySearchParms(params) {
      if(typeof localStorage != "undefined") {
        localStorage.setItem('agencySearchParms', JSON.stringify(params));
        this.agencySearchParms = params;
      }
    }

    public getAgencySearchParms() {
      return this.agencySearchParms;
    }

    public setInvestSearchParms(params) {
      if(typeof localStorage != "undefined") {
        localStorage.setItem('investSearchParms', JSON.stringify(params));
        this.investSearchParms = params;
      }
    }

    public getInvestSearchParms() {
      return this.investSearchParms;
    }

    getLocUrl_Agency(location,city,byCity,cityId,purpose,type,sub_type){
      if(byCity) {
          let url =  '/' + purpose + '/' + type + '/' + city  + "-" + cityId + "-1"; // 1 in params specify its a City
          return url;
      }
    }

    renderLocURL(slug) {
      slug = slug.split("-");
      return { id: slug[slug.length - 2], name: slug[0], searchBy: slug[slug.length - 1] };
    }

    get_FindPro_URL(location, city,  byCity, category) {
      if(byCity) {
          let url =  '/find-pro/' + category + '/' + city.name.replace(/ /g, "-")  + "-" + city.id + "-1"; // 1 in params specify its a City
          return url;
      } else {
          let url = '/find-pro/' + category + '/' + location.slug.replace(/ /g, "-").replace(/_/g, "-").replace(/[/\\*]/g, "-") + "-" + location.id + "-2"; // 2 in params specify its a sub location;
          return url;
      }
    }

    render_FindPro_URL(slug) {
      slug = slug.split("-");
      return { id: slug[slug.length - 2], name: slug[0], searchBy: slug[slug.length - 1] };
    }

    getParentLocations(id): Promise<any> {
      const url = AppSettings.API_ENDPOINT + "locations/get-parent-locations-by-sublocation?sub_loc=" + id;
      return this.httpGetRequests(url).then(resp => {

        if (typeof resp.p_loc != "undefined") {
            let searchedArea = resp.p_loc.slice()[0];

            let parentLocs  = [...resp.p_loc.slice().reverse()];

            // Remove the Last parent as its the same as searched area
            parentLocs.pop();

            return {searchedArea: searchedArea, parentLocs: parentLocs};
        }
      }).catch(error => {
          console.log("error: ",error);
      })
    }

    checkIfLocationAlreadyExists(sublocalities, locationName) {
      for (let index = 0; index < sublocalities.length; index++) {
        let loc = sublocalities[index];
        if(loc.name == locationName) {
          sublocalities.splice(index, 1);
        }
      }
      return sublocalities;
    }

    replaceString(s) {
      if(s){
        return s.toLowerCase().replace(/ /g, '-').replace(/_/g, '-').replace(/[/\\*]/g, "-").replace(/---/g, '-');

      }
    }

    propertyRoute(property) {
      let propID;
      if(typeof property.p_id != "undefined")
        propID = property.p_id;
      else
        propID = property.id;
      return '/property/' + this.replaceString(property.location_name) + '-' + this.replaceString(property.title) + '-' + propID;
    }

    franchiseRoute(franchise) {
      let propID;
      propID = franchise.id;
      return '/franchise/' + this.replaceString(franchise.name) + '-' + propID;
    }

    applyFranchiseRoute(franchise) {
      let propID;
      propID = franchise.id;
      return '/apply-for-franchise/' + this.replaceString(franchise.name) + '-' + propID;
    }

    rejectedImageRoute(property) {
      let propID;
      if(typeof property.p_id != "undefined")
        propID = property.p_id;
      else
        propID = property.id;
      return '/dashboard/rejected-images/' + propID;
    }
    rejectedVideoRoute(property) {
      let propID;
      if(typeof property.p_id != "undefined")
        propID = property.p_id;
      else
        propID = property.id;
      return '/dashboard/rejected-videos/' + propID;
    }

    renderPropertyURL(slug) {
      slug = slug.split("-");
      return { id: slug[slug.length - 1], name: slug[0] };
    }

    propertyEditRoute(property) {
      let propID;
      if(typeof property.p_id != "undefined")
        propID = property.p_id;
      else
        propID = property.id;

      return '/dashboard/edit-property/' + this.replaceString(property.location_name) + '-' + this.replaceString(property.title) + '-' + propID;
    }

    propertyRepostRoute(property) {
      let propID;
      if(typeof property.p_id != "undefined")
        propID = property.p_id;
      else
        propID = property.id;

      return '/dashboard/repost-property/' + this.replaceString(property.location_name) + '-' + this.replaceString(property.title) + '-' + propID;
    }

    renderEditPropertyURL(slug) {
      slug = slug.split("-");
      return { id: slug[slug.length - 1], name: slug[0] };
    }

    investRoute(city) {
      let routeLink =  '/projects/' + city.name.replace(/ /g, "_") + "-" + city.id + "-1"; // 1 in params specify its a City

      return routeLink;
    }

    renderInvestURL(slug) {
      slug = slug.split("-");
      return { id: slug[slug.length - 1], name: slug[0] };
    }

    agencyProfileRoute(agent) {
      let agentID;
      if(typeof agent.id != "undefined")
        agentID = agent.id;
      else
        agentID = agent.agency.id;
      return '/agent/' + this.replaceString(agent.agency.city) + '-' + this.replaceString(agent.agency.name) + '-' + agentID;
    }


    renderAgencyProfileURL(slug) {
      slug = slug.split("-");
      return { id: slug[slug.length - 1], name: slug[0] };
    }


    get_blog_URL(blog) {
      return '/blog/' + blog.title.replace(/ /g, "-").replace(/---/g, "-");
      // return '/blog/' + blog.title.replace(/ /g, "-").replace(/---/g, "-") + "-" + blog.id;
    }

    render_blog_URL(slug) {
      slug = slug.split("-");
      return { id: parseInt(slug[slug.length - 1]) };
    }

    get_author_URL(blogger) {
      return '/author/' + blogger.blogger.name.replace(/ /g, "-") + "-" + blogger.blogger_id;
    }

    render_author_URL(slug) {
      slug = slug.split("-");
      return { id: parseInt(slug[slug.length - 1]) };
    }

    get_category_URL(category) {
    return '/category/' + category.name.replace(/ /g, "-") + "-" + category.id;
    }

    render_category_URL(slug) {
    slug = slug.split("-");
    return { id: parseInt(slug[slug.length - 1]) };
    }

    getCORS_Headers() {
      return this.corsHeaders;
    }

    getPagination(current, last) {
      let delta = 1,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || i >= left && i < right) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push('...');
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    }

    //////////////////////////////////////////
    /********* HTTP Requests Fns ***********/
    ////////////////////////////////////////
    async httpGetRequests(url): Promise<any> {
        // Set loader true
        this.httpLoading = true;

        return this.http.get(url, this.corsHeaders)
            .pipe( takeUntil(this.ngUnsubscribe) )
            .toPromise()
            .then( resp => {
                // Set loader false
                this.httpLoading = false;

                return resp;
                // console.log("resp: ",resp);
            })
            .catch(error => {
                // Set loader false
                this.httpLoading = false;
                console.log("helperFunc error: ",error);

                // Show Error Msg
                if(typeof error.error != "undefined") {
                    if(error.error.message == "Unauthenticated.") {
                      this.authenticationService.logout();
                    }

                    throw error;
                } else {
                    throw "Something went wrong. Please try again.";
                }
            });
      }

      httpPostRequests(url, data, corsHeaders = this.corsHeaders): Promise<any> {
          // Set loader true
          this.httpLoading = true;

          return this.http.post(url, data, corsHeaders)
              .pipe( takeUntil(this.ngUnsubscribe) )
              .toPromise()
              .then( resp => {
                  // Set loader false
                  this.httpLoading = false;
                  return resp;
              })
              .catch(error => {
                  // Set loader false
                  this.httpLoading = false;
                  console.log("error: ",error);

                  // Show Error Msg
                  if(typeof error.error != "undefined") {
                      if(error.error.message == "Unauthenticated.") {
                        this.authenticationService.logout();
                      }

                      throw error;
                  } else {
                      throw "Something went wrong. Please try again.";
                  }
              });
        }

        httpPutRequests(url, data): Promise<any> {
            // Set loader true
            this.httpLoading = true;

            return this.http.put(url, data, this.corsHeaders)
                .pipe( takeUntil(this.ngUnsubscribe) )
                .toPromise()
                .then( resp => {
                    // Set loader false
                    this.httpLoading = false;
                    return resp;
                })
                .catch(error => {
                    // Set loader false
                    this.httpLoading = false;
                    console.log("error: ",error);

                    // Show Error Msg
                    if(typeof error.error != "undefined") {
                        if(error.error.message == "Unauthenticated.") {
                          this.authenticationService.logout();
                        }

                        throw error;
                    } else {
                        throw "Something went wrong. Please try again.";
                    }
                });
          }

      httpDeleteRequests(url): Promise<any> {
          // Set loader true
          this.httpLoading = true;

          return this.http.delete(url, this.corsHeaders)
              .pipe( takeUntil(this.ngUnsubscribe) )
              .toPromise()
              .then( resp => {
                  // Set loader false
                  this.httpLoading = false;

                  console.log("resp: ",resp);
                  return resp;
              })
              .catch(error => {
                  // Set loader false
                  this.httpLoading = false;

                  console.log("error: ",error);

                  // Show Error Msg
                  if(typeof error.error != "undefined") {
                      if(error.error.message == "Unauthenticated.") {
                        this.authenticationService.logout();
                      }

                      throw error;
                  } else {
                      throw "Something went wrong. Please try again.";
                  }
              });
        }
}
