import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FranchiseService, AuthenticationService } from '../../../services/_services';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import Swal from 'sweetalert2';
declare var $: any, Swiper: any, window: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    public initSidebar    : any = false;
    public windowScrolled : any = false;
    public initAccordion  : any = false;
    public initDropdown   : any = true;
    public preventClose   : any = true;
    public loggedUser     : any = null;
    public subs           : any = { browser: null, auth: null };
    public rendererSub    : any = null;

    @ViewChild('toggleButton') toggleButton: ElementRef;
    @ViewChild('menu') menu: ElementRef;

  constructor(
    private franchiseService        : FranchiseService,
    private authenticationService :  AuthenticationService,
    private router                 : Router,
    private activatedRoute         : ActivatedRoute,
    private renderer: Renderer2
  ) {
      this.subs.browser = this.authenticationService.isBrowser.subscribe(isBrowser => {
        if(isBrowser) {
          // Get Logged User
          this.subs.auth = this.authenticationService.currentUserS$.subscribe(user => {
            if (user) {
               this.loggedUser =  Object.assign({}, user);
            } else {
               this.loggedUser = null;
            }
          })

          // Rendrer for header dropdown
          this.rendererSub = this.renderer.listen('window', 'click',(e:Event) => {
              if(e.target !== this.toggleButton.nativeElement && e.target!==this.menu.nativeElement && !this.preventClose){
                  this.initDropdown = true;
                  if(typeof $ != "undefined") {
                      $(".login-dropdown").slideUp("fast");
                  }
              }
               this.preventClose=false;
          });

          // Set Header
          this.headerFixed();
        }
      });
   }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    // Unsunscribe All subscription
    for (const key in this.subs) {
      if(this.subs[key]) {
        this.subs[key].unsubscribe();
      }
    }

    // Remove renderer sub
    if(this.rendererSub) {
      this.rendererSub();
    }
  }

  setFranchiseNaivgate() {
    this.franchiseService.setFranchiseNavigate(null, null);  // Reset the service to defualt
  }

  // Sidebar
  togglSidebar() {
      let element = document.getElementsByTagName("body")[0];
      this.initSidebar = !this.initSidebar;

      if(this.initSidebar) {
        element.classList.add("overflow-hidden");
      }
      else {

        element.classList.remove("overflow-hidden");
      }
  }

  // Header Shrink
  headerFixed() {
    window.addEventListener("scroll", () => {
        if (window.scrollY > 10) {
            this.windowScrolled = true;
        } else {
            this.windowScrolled = false;
        }
    });
  }

  // User Accordion
  toogleAccordion() {
      this.initAccordion = !this.initAccordion;

      if(this.initAccordion) {
          $(".userAaccordion-body").slideDown("fast");
      }
      else {
          $(".userAaccordion-body").slideUp("fast");
      }
  }
  // scrolToTop(){
  //   if(typeof $ != "undefined"){
  //             setTimeout(() => {
  //               window.scroll({ top: 0,left:0, behavior: 'smooth' });
  //             }, 10);
  //         }
  // }

  preventCloseOnClick() {
    this.preventClose = true;
  }

  // Login Dropdown
  loginDropdown() {
      this.initDropdown = !this.initDropdown;

      if(this.initDropdown) {
          $(".login-dropdown").slideUp("fast");
      }
      else {
          $(".login-dropdown").slideDown("fast");
      }
  }

  logout() {
      // let route = this.franchiseService.getFranchiseNavigate().route;
    // // Log user out from Social Login
    // if (this.authService['_user'] != null)
    //   this.signOut();

    // Show Loader
    //this.loading = true;
    // this.router.navigate(['/']);

    this.authenticationService.simpleLogout();
    Swal.fire({
           html: '<div class="svg_pop_wrapper"><div class="mem_svg_wrap"><img src="assets/images/new/success.svg"></div><p>Logout Successfully!</p></div>',
           showCancelButton: false,
           showConfirmButton: false,
           timer: 1500
        });

    this.router.navigateByUrl('/');


  }

}
