import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })

export class SaveContinueService {
    public saveContinueProp     : any;

    constructor() {
      if (typeof localStorage != 'undefined') {
        this.saveContinueProp = JSON.parse(localStorage.getItem('saveContinueProp'));
      }
    }

    getSaveContinueProp() {
      return this.saveContinueProp;
    }

    updateSaveContinueProp(prop) {
      this.saveContinueProp = prop;

      // Set to local storage
      if (typeof localStorage != 'undefined')
        localStorage.setItem('saveContinueProp', JSON.stringify(this.saveContinueProp));
    }

    removeSaveContinueProp() {
      this.saveContinueProp = null;

      // Remove from local storage
      if (typeof localStorage != 'undefined')
        localStorage.removeItem('saveContinueProp');
    }
}
