import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(typeof this.authenticationService.get_currentUserValue() != "undefined") {
            const currentUser = this.authenticationService.get_currentUserValue();
            if (currentUser) {
                // authorised so return true
                return true;
            }

            // not logged in so redirect to login page with the return url
            // this.router.navigate(['/sign-in'], { queryParams: { returnUrl: state.url }});
            this.router.navigate(['/sign-in']);
            return false;
        }

        return true;
    }
}
