import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppSettings } from './app.setting';

@Injectable({ providedIn: 'root' })

export class AOSService {
    public aos: any;

    constructor(
    ) {
    }

    injectAOSLibrary() {
      return new Promise(async (resolve) => {
        let self = this;
        if(typeof window != "undefined") {
          if(self.aos)
            resolve(window['AOS']);

          (window as any).onload = function() {
            self.aos = window['AOS'];
            resolve(self.aos);
          };

          // Add Css
          (function(d, s, id) {
             var css, fjs = d.getElementsByTagName(s)[0];
             if (d.getElementById(id)) {return;}
             css = d.createElement(s);
             css.id = id;
             css.rel = "stylesheet";
             css.href = "assets/css/aos/aos.css";
             fjs.parentNode.insertBefore(css, fjs);
           }(document, 'link', 'aos-cssSdk'));

           // Add JS
           (function(d, s, id) {
              var js, fjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) {return;}
              js = d.createElement(s);
              js.id = id;
              js.async = true;
              js.defer = true;
              js.src = "assets/css/aos/aos.js";
              fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'aos-jsSdk'));
        }
      });
    }
}
