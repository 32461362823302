import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HelperService } from './helperFns.service';

@Injectable({ providedIn: 'root' })

export class SaveSearchService {
    public searches     : any;
    public searchesURL  : any = "";
    private searchesS   : BehaviorSubject<any> = new BehaviorSubject<any>(this.searches);
    searchesS$          : Observable<any> = this.searchesS.asObservable();

    constructor(
      private helperService : HelperService
    ) {
      if (typeof localStorage != 'undefined') {
        this.searches = JSON.parse(localStorage.getItem('searchedProps'));
        if(this.searches == null)
          this.searches = {};

        this.searchesS.next(this.searches);

        this.searchesURL = JSON.parse(localStorage.getItem('Search_URL'));
        if(this.searchesURL == null)
          this.searchesURL = "";
        }
    }

    getSearch_URL() {
      return this.searchesURL;
    }

    updateSearch_URL(url) {
      this.searchesURL = url;

      // Set to local storage
      if (typeof localStorage != 'undefined')
        localStorage.setItem('Search_URL', JSON.stringify(this.searchesURL));
    }

    getSearch() {
      return this.searches;
    }

    updateSearch(data: any) {
      // Empty searches
      this.searches = {};

      // Update with new searches
      for (let i = 0; i < data.length; i++) {
          this.searches[data[i].p_id] = data[i];
      }
      this.searchesS.next(this.searches);

      // Set to local storage
      if (typeof localStorage != 'undefined')
        localStorage.setItem('searchedProps', JSON.stringify(this.searches));
    }

    propertyRoute(property) {
      return this.helperService.propertyRoute(property);
    }

    getPrevUrl(propID) {
      var keys  = Object.keys(this.searches);

      for (let i = 0; i < keys.length; i++) {
          if(propID == keys[i]) {
            i = i - 1;
            if(typeof keys[i] != "undefined") {
                return this.propertyRoute(this.searches[keys[i]]);
            } else {
                return false;
            }
            break;
          }
      }
    }

    getNextvUrl(propID) {
      var keys  = Object.keys(this.searches);

      for (let i = 0; i < keys.length; i++) {
          if(propID == keys[i]) {
            i = i + 1;
            if(typeof keys[i] != "undefined") {
                return this.propertyRoute(this.searches[keys[i]]);
            } else {
                return false;
            }
            break;
          }
      }
    }
}
