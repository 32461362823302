import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppSettings, HelperService } from './services/_services';

declare var $: any, Swiper: any, window: any, gtag: any;
import Swal from 'sweetalert2'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = '';
  public subscription             : any = { email: ''};
  public subscriptionForm         : FormGroup;
  public lpModal                  : any = false;
  public newsLetterSuccess        : any = false;
  public newsLetterLoader         : any = false;
  public submitted                : any = false;
  public routerSubscription       : any;
  public showheader               : any = true;
  public showFooter               : any = true;

  constructor(
    public router                   : Router,
    public swPush                   : SwPush,
    public swUpdate                 : SwUpdate,
    public helperService            : HelperService,
    private formBuilder             : FormBuilder,
    private cdr                     : ChangeDetectorRef
  ){
    this.routerSubscription = this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        if(val.url.indexOf('dashboard') != -1){
          this.showheader    = false;
          this.showFooter    = false;
        }
        else if(val.url.indexOf('sign-in') != -1 || val.url.indexOf('login') != -1 || val.url.indexOf('sign-up') != -1 || val.url.indexOf('forgot-password') != -1 || val.url.indexOf('update-password') != -1){
          this.showheader = false;
          this.showFooter = false;
        }
        else{
          this.showheader = true;
          this.showFooter = true;
        }
       }
    });


    // Window ScrollTop on route change
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        if(typeof $ != "undefined"){
            setTimeout(() => {
              // window.scroll({ top: 0,left:0, behavior: 'smooth' });
              window.scrollTo(0, 0);
          }, 100);
        }
    });
  }

  ngOnInit() {
    // console.log('*** application updated!');
    // console.log('*** this.swUpdate: ',this.swUpdate );
    if (this.swUpdate.isEnabled) {
      // this.swUpdate.available.subscribe((evt) => {
      //   console.log('service worker updated');
      // });
      //
      // this.swUpdate.checkForUpdate().then(() => {
      //   // noop
      // }).catch((err) => {
      //   console.error('error when checking for update', err);
      // });

      this.swUpdate.available.subscribe(event => {
        console.log('current version is', event.current);
        console.log('available version is', event.available);
      });
      this.swUpdate.activated.subscribe(event => {
        console.log('old version was', event.previous);
        console.log('new version is', event.current);
      });

      this.swUpdate.available.subscribe(event => {
        this.swUpdate.activateUpdate().then(() => this.updateApp());
      });
    }
    this.subscriptionForm = this.formBuilder.group({
      email    : [null, [Validators.required, Validators.email]],
    });
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    let self = this;
    setTimeout(() => {
      self.serviceOffModal();
    }, 3000);
  }

  get f() { return this.subscriptionForm.controls; }

  AddSubscribe(){
    this.submitted        = true;

    // Stop here if form is invalid
    if (this.subscriptionForm.invalid) {
     return;
    }

    this.newsLetterLoader = true;
    let url  = AppSettings.API_ENDPOINT + 'subscribers/add';
    this.subscription.is_launch_lead = true;

    this.helperService.httpPostRequests(url, this.subscription)
      .then(resp => {
        //console.log("resp: ",resp);
        this.newsLetterLoader = false;
        if (typeof resp != "undefined" && resp != null && typeof resp.error === "undefined") {
          this.subscription = { email: ''};

          this.newsLetterSuccess = true;
          this.cdr.detectChanges();
          let self = this;
          setTimeout(() => {
            self.newsLetterSuccess = false;
            self.cdr.detectChanges();
          }, 5000);

          // Set Submission false
          this.submitted = false;
        } else {
            console.error(resp);
        }
      })
      .catch(error => {
        console.log("error: ",error);
        this.newsLetterLoader = false;

        Swal.fire('Newsletter Subscription', "You have already subscribed to the newsletter" , 'warning');
        // Set Submission false
        this.submitted = false;
      });
  }

  serviceOffModal(){
    this.lpModal = true;
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this.routerSubscription)
      this.routerSubscription.unsubscribe();
  }

  updateApp() {
    document.location.reload();
    console.log("The app is updating right now");
  }
}
