import { Injectable, ViewChild, ElementRef } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HelperService } from './helperFns.service';
import { AppSettings } from './app.setting';

@Injectable({ providedIn: 'root' })

export class GoogleLoginService {

  @ViewChild('loginRef', {static: true }) loginElement: ElementRef;
  public google: any;

  constructor(
    private helperService : HelperService
  ) {
  }

  googleSDK() {
    return new Promise(async (resolve) => {
      let self = this;
      if(typeof window != "undefined") {
        if(this.google)
          resolve(this.google);

        window['googleSDKLoaded'] = () => {
          window['gapi'].load('auth2', () => {
            self.google = window['gapi'].auth2.init({
              client_id    : AppSettings.GOOGLE_LOGIN_KEY,
              cookiepolicy : 'single_host_origin',
              scope        : 'profile email'
            });
            // self.prepareLoginButton();

            resolve(this.google);
          });
        }

        (function(d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {return;}
          js = d.createElement(s); js.id = id;
          js.async = true;
          js.defer = true;
          js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'google-jssdk'));
      }
    });
  }

  login(): Promise<any> {
    // Sign the user in, and then retrieve their ID.
    let self = this;
    return this.google.signIn().then(function() {
      var profile      = self.google.currentUser.get().getBasicProfile();
      var access_token = self.google.currentUser.get().getAuthResponse().access_token;
      let user = {
                    firstName : profile.getGivenName(),
                    lastName  : profile.getFamilyName(),
                    email     : profile.getEmail(),
                    photoUrl  : profile.getImageUrl(),
                    id        : profile.getId(),
                    authToken : access_token,
                    provider  : 'GOOGLE'
                 }
      return user;
    });
  }

  logout() {
    if(typeof this.google != 'undefined') {
      this.google.signOut().then(function () {
        console.log('Google User logout: ',);
      });
    }
  }
}
