import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class FranchiseService {
    public franchise     : any = { id: null, route: null, formStep: null, newStep: null };

    constructor() {
      // if (typeof localStorage != 'undefined') {
      //   this.franchise = JSON.parse(localStorage.getItem('franchiseNavigate'));
      //   if(this.franchise == null)
      //     this.franchise = { id: null, route: null };
      // }
    }

    getFranchiseNavigate() {
      return this.franchise;
    }

    setFranchiseNavigate(franchiseId, route, formStep = 0,newStep=0) {
      this.franchise = { id: franchiseId, route: route, formStep: formStep, newStep:newStep };
      // localStorage.setItem('franchiseNavigate', JSON.stringify(this.franchise));
    }


}
