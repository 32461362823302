import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  scrolToTop(){
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  //whatsapp
  openWhatsapp() {
    if(typeof window != "undefined"){
      window.open("https://api.whatsapp.com/send?phone=923460000007&text=Hi%2C%20I%20would%20like%20to%20schedule%20a%20call%20regarding%20franchise%20ownership.%20Please%20contact%20me%20back%20at%20your%20earliest.%20Thanks", "_blank");
    }
  }

}
